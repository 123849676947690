import { Pipe, PipeTransform } from '@angular/core';
import { Device } from 'src/app/core/models/project/devices/device.model';
import { EquipmentPropertyTypesService } from '../services/equipment-property-types.service';

@Pipe({
  name: 'deviceFilter'
})
export class DeviceFilterPipe implements PipeTransform {

  constructor(private equipmentPropertyTypesService: EquipmentPropertyTypesService) {}

  transform(devices: any[], mode: 'action' | 'condition' | 'admin'): any[] {
    if (!devices) {
      return [];
    }
    if (!mode) {
      return devices;
   }
    if (mode === 'admin') {
      return devices.filter( (device: any) => {
        // let showDevice = false;
        // device.equipmentProperties.some( prop => {
        //   if (this.equipmentPropertyTypesService.getEquipmentPropertyTypeForStatus(prop.type)) {
        //     showDevice = true;
        //     return true;
        //   }
        // });
        return devices;
      });
    }
    else if (mode === 'action') {
      return devices.filter((device: any) => {
        return device.isActuator;
      });

    } else if (mode === 'condition') {
      return devices.filter((device: any) => {
        return device.isCondition;
      });
    }
  }
}

