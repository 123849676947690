import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { User } from 'src/app/core/models/user/user.model';
import { ApiScenesService } from 'src/app/modules/scenes/api-scenes.service';
import { ScenesService } from 'src/app/modules/scenes/scenes.service';
import { Scene } from 'src/app/core/models/scenes/scene.model';
import { Observable, Subscription } from 'rxjs';
import { ApiModesService } from 'src/app/modules/modes/services/http/api-modes.service';
import { ModesService } from 'src/app/modules/modes/services/modes.service';
import { Mode } from 'src/app/core/models/modes/mode.model';
import { ApartmentService } from 'src/app/shared/services/apartment.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { DemoService } from 'src/app/core/services/demo.service';
import { AlertController, Platform, ToastController } from '@ionic/angular';
// import { Plugins } from '@capacitor/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { Action } from 'src/app/core/models/automation/action.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { Property } from 'src/app/core/models/project/property.model';
import { Hvac } from 'src/app/core/models/project/devices/hvac.model';
import { Device } from 'src/app/core/models/project/devices/device.model';
import { Project } from 'src/app/core/models/project/project.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { EquipmentPropertyTypesService } from 'src/app/shared/services/equipment-property-types.service';
import { EquipmentComponentGenService } from 'src/app/shared/services/equipment-component-gen.service';
import { RoomViewService } from 'src/app/modules/rooms/services/room-view.service';
import { GenComTranslatePipe } from 'src/app/shared/pipes/gen-com-translate.pipe';
import { DaliProjectService } from 'src/app/modules/project/services/dali-project.service';
import { ApiDaliProjectService } from 'src/app/modules/project/services/http/api-dali-project.service';
import { DaliProject } from 'src/app/core/models/project/dali-project.model';
import { RgbLight } from 'src/app/core/models/project/devices/rgbLight.model';
import { USE_DALI, USE_DEVICE_VISIBLE_TO_USER } from 'src/environments/environment';
import { ApiDaliScenesService } from 'src/app/modules/scenes/api-dali-scenes.service';
import { App } from '@capacitor/app';
import { ToastService } from 'src/app/shared/services/toast.service';
import { HttpClient } from '@angular/common/http';
import { ConfigLoaderService } from 'src/app/core/app-load/config-loader.service';



// const { App } = Plugins;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  signedInUser: User;

  scenes: Scene[] = [];
  sceneSubscription: Subscription;

  modes: Mode[] = [];
  modesSubsctiption: Subscription;
  apartmentId$: Observable<string>;

  loadingSubscription: Subscription;
  loadingElements = [];

  backButtonPressed = false;
  backButtonPressedTimer: any;
  backButtonSubscription: Subscription;

  project: Project = this.projectService.getProject();
  projectSubscription: Subscription;

  daliProject: DaliProject = this.daliProjectService.getProject();
  daliProjectSubscription: Subscription;

  private readonly CONFIG_URL = 'assets/config/config.json';
  webConfig;

  constructor(
    private userService: UserService,
    private apiModesService: ApiModesService,
    private modesService: ModesService,
    private apiScenesService: ApiScenesService,
    private scenesService: ScenesService,
    private apartmentService: ApartmentService,
    private loadingService: LoadingService,
    private demoService: DemoService,
    private platform: Platform,
    private router: Router,
    private authService: AuthenticationService,
    private toastController: ToastController,
    private projectService: ProjectService,
    private apiProjectService: ApiProjectService,
    private equipmentPropertyTypesService: EquipmentPropertyTypesService,
    private equipmentComponentGen: EquipmentComponentGenService,
    private roomViewService: RoomViewService,
    private pipe: GenComTranslatePipe,
    private daliProjectService: DaliProjectService,
    private apiDaliProjectService: ApiDaliProjectService,
    private apiDaliScenesService: ApiDaliScenesService,
    private alertController: AlertController,
    private toastService: ToastService,
    private http: HttpClient,
    private configLoaderService: ConfigLoaderService

    ) {}

  ngOnInit() {
    if (this.platform.is('mobile') && !this.demoService.isDemo()) {
      this.configLoaderService.checkConfigForUpdates();
    }
    if (!this.demoService.isDemo()) {
      if (this.platform.is('desktop')) {
        this.http.get<any>(this.CONFIG_URL).subscribe({
          next: (value) => this.webConfig = value
        });
      } else {
        this.webConfig = this.configLoaderService.getConfig().configuration;
      }
    }

    this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(10, async () => {
      if (this.demoService.isDemo()) {
        this.userService.unsetUser();
        this.authService.logout();
        this.demoService.setDemo(false);
        this.router.navigate(['/landing']);
      } else {
        await this.backButtonPress();
      }
    });
    this.apartmentId$ = this.apartmentService.getApartmentId();
    this.signedInUser = Object.assign(new User(), this.userService.getUser());
    this.getScenes();
    this.getModes();
    this.loadingSub();
    if (USE_DALI) {
      this.daliProjectSubscription = this.daliProjectService.daliProjectChanged.subscribe(() => {
        this.daliProject = this.daliProjectService.getProject();
      });
    }
    this.projectSubscription = this.projectService.projectChanged.subscribe(() => {
      this.project = this.projectService.getProject();});
  }


  async backButtonPress(){
    if (this.backButtonPressed){
      App.exitApp();
    } else {
      const toast = await this.toastController.create({
        message: this.pipe.transform('Press back button again to exit app.'),
        duration: 2000
      });
      toast.present();
      this.backButtonPressed = true;
      if (this.backButtonPressedTimer) {
        clearTimeout(this.backButtonPressedTimer);
      }
      this.backButtonPressedTimer = setTimeout(() => {
        this.backButtonPressed = false;
      }, 2000);
    }
  }

  loadingSub() {
    this.loadingElements = this.loadingService.getLoadingElements();
    this.loadingSubscription = this.loadingService.loadingChanged.subscribe( response => {
      this.loadingElements = this.loadingService.getLoadingElements();
    });
  }

  loadingElement(id: string) {
    return this.loadingElements.includes(id);
  }

  loadingHeatingOrCoolingElement(mode: Mode) {
    const coolingMode: Mode = this.modes.find((m: Mode) => {
      return Mode.isCooling(m) && m.apartmentId === mode.apartmentId;
    });
    const heatingMode: Mode = this.modes.find((m: Mode) => {
      return Mode.isHeating(m) && m.apartmentId === mode.apartmentId;
    });
    return this.loadingElements.includes(coolingMode._id) || this.loadingElements.includes(heatingMode._id);

  }

  heatOrCoolingModeNotActive(aptId: string) {
    if (this.modes) {
      const coolingMode: Mode = this.modes.find((m: Mode) => {
        return Mode.isCooling(m) && aptId === m.apartmentId;
      });
      const heatingMode: Mode = this.modes.find((m: Mode) => {
        return Mode.isHeating(m) && aptId === m.apartmentId;
      });
      return coolingMode?.isActive === false && heatingMode?.isActive === false;
    }
  }

  getScenes() {
    this.scenes = this.scenesService.getScenes();
    this.apiScenesService.getScenes().subscribe();
    this.sceneSubscription = this.scenesService.scenesChanged.subscribe((resp) => {
      this.scenes = this.scenesService.getScenes();
    });
  }

  getAllScenes() {
    if (USE_DALI) {
      if (this.daliProject?.listOfScenes &&  this.scenes) {
        return [...this.daliProject?.listOfScenes, ...this.scenes];
      }
    } else if (this.scenes){
      return this.scenes;
    }
  }

  getModes() {
    this.modes = this.modesService.getModes();
    this.apiModesService.getModes().subscribe();
    this.modesSubsctiption = this.modesService.modesChanged.subscribe(() => {
      this.modes = this.modesService.getModes();
      if (this.demoService.isDemo()) {
        this.demoService.setModesForDemo(this.modes);
        this.modes = this.demoService.getModesForDemo();
      }
    });
  }

  showIsSceneActive(isActive: boolean) {
    if (this.webConfig) {
      if (isActive && this.webConfig.SHOW_SCENE_STATUS) {
        return true;
      }
    }
  }

  onSceneClick(scene) {
    if (this.demoService.isDemo()) {
      this.loadingService.addToLoading(scene._id);
      // const targetScene = this.scenesService.getSceneById(scene._id);
      scene.actions.actions.forEach( action => {
        this.runSceneActionForDemo(action);
      });
      this.loadingService.removeFromLoading(scene._id);
    }else {

      const isDaliScene = scene?.id?.split('-')[0] === 'DS';
      if (isDaliScene) {
        this.loadingService.addToLoading(scene.id);
        if (this.webConfig.SHOW_SCENE_STATUS) {
          if (scene.active) {
            this.apiDaliScenesService.deactivateDaliScene(scene.id).subscribe({
              next: (resp) => {
                this.loadingService.removeFromLoading(scene.id);
                }
          });
          }else {
            this.apiDaliScenesService.activateDaliScene(scene.id).subscribe({
              next: (resp) => {
                this.loadingService.removeFromLoading(scene.id);
                }
          });
          }
        } else {
          this.apiDaliScenesService.activateDaliScene(scene.id).subscribe({
            next: (resp) => {
              this.toastService.showSceneTurnedOn();
              this.loadingService.removeFromLoading(scene.id);
              }
        });
        }
      }
      else {
        this.loadingService.addToLoading(scene._id);
        if (this.webConfig.SHOW_SCENE_STATUS) {
          if (scene.isActive) {
            this.apiScenesService.deactivateScene(scene._id).subscribe({
              next: (resp) => {
                this.loadingService.removeFromLoading(scene._id);
                }
          });
          }else {
            this.apiScenesService.activateScene(scene._id).subscribe({
              next: (resp) => {
                this.loadingService.removeFromLoading(scene._id);
                }
          });
          }

        }else {
          this.apiScenesService.activateScene(scene._id).subscribe({
            next: (resp) => {
              this.toastService.showSceneTurnedOn();
              this.loadingService.removeFromLoading(scene._id);
              }
        });
        }
      }
    }
  }

  runSceneActionForDemo(action: Action) {
    const deviceId = action.parameters[0].split('/')[0];
    const type = action.parameters[0].split('/')[1];

    const device = this.projectService.findDeviceById(deviceId);
    const prop = device.equipmentProperties.find( element => {
      if (+element.type === +type) { return true; }
    });

    prop.value = +action.parameters[1];

  }

  demoModeSetHeatingAndCooling(activeMode: string) {
    const project = this.projectService.getProject();
    project['listOfHvacs'].forEach( element => {
      const heatOrcoolingMode = element.equipmentProperties.find(prop => {
        if (Property.isHvacMode(prop)) { return true; }
      });
      if (activeMode === 'heating') {
        heatOrcoolingMode.value = 0;
      } else {
        heatOrcoolingMode.value = 1;
      }
    });
    this.modesService.setModes(this.modes);
  }

  async onModeClick(mode: Mode) {
    const alertHeader = this.pipe.transform('Switch mode');
    let alertMsg = this.pipe.transform(`Confirm switch mode?`);
    if (Mode.isHeating(mode)) {
      alertMsg = this.pipe.transform('Turn on cooling mode?');
    } else if (Mode.isCooling(mode)) {
      alertMsg = this.pipe.transform('Turn on heating mode?');
    }
    const alert = await this.alertController.create({
      header: alertHeader,
      message: alertMsg,
      buttons: [ {
        text: this.pipe.transform('Cancel'),
        role: 'cancel',
      },
        {
          text: this.pipe.transform('Confirm'),
          handler: () => {
            this.onModeClickConfirm(mode);
          }
        }
      ],
    });
    await alert.present();
   }


  onModeClickConfirm(mode: Mode) {
    if (this.demoService.isDemo()) {
      this.loadingService.addToLoading(mode._id);
      if (Mode.isHeating(mode)) {
        const coolingMode: Mode = this.modes.find((m: Mode) => {
          return Mode.isCooling(m) && m.apartmentId === mode.apartmentId;
        });
        mode.isActive = false;
        coolingMode.isActive = true;
        this.demoModeSetHeatingAndCooling('cooling');
        this.loadingService.removeFromLoading(mode._id);
      } else if (Mode.isCooling(mode)) {
        const heatingMode: Mode = this.modes.find((m: Mode) => {
          return Mode.isHeating(m) && m.apartmentId === mode.apartmentId;
        });
        mode.isActive = false;
        heatingMode.isActive = true;
        this.demoModeSetHeatingAndCooling('heating');
        this.loadingService.removeFromLoading(mode._id);
      } else if (!mode.isActive) {
        mode.isActive = true;
        this.loadingService.removeFromLoading(mode._id);
      } else {
        mode.isActive = false;
        this.loadingService.removeFromLoading(mode._id);
      }
    } else {
      this.loadingService.addToLoading(mode._id);
      if (Mode.isHeating(mode)) {
        const coolingMode: Mode = this.modes.find((m: Mode) => {
          return Mode.isCooling(m) && m.apartmentId === mode.apartmentId;
        });
        this.apiModesService.deactivateMode(mode._id).subscribe(() => {
          this.apiModesService.activateMode(coolingMode._id).subscribe(() => {
            this.loadingService.removeFromLoading(mode._id);
          });
        });
      } else if (Mode.isCooling(mode)) {
        const heatingMode: Mode = this.modes.find((m: Mode) => {
          return Mode.isHeating(m) && m.apartmentId === mode.apartmentId;
        });
        this.apiModesService.deactivateMode(mode._id).subscribe(() => {
          this.apiModesService.activateMode(heatingMode._id).subscribe(() => {
            this.loadingService.removeFromLoading(mode._id);
          });
        });
      } else if (!mode.isActive) {
        this.apiModesService.activateMode(mode._id).subscribe(() => {
          this.loadingService.removeFromLoading(mode._id);
        });
      } else {
        this.apiModesService.deactivateMode(mode._id).subscribe(() => {
          this.loadingService.removeFromLoading(mode._id);
         });
      }
    }
  }

  modeIsHeating(mode: Mode): boolean {
    return Mode.isHeating(mode);
  }

  heatingOrCooling(mode: Mode): boolean {
    return Mode.isHeating(mode) || Mode.isCooling(mode);
  }

  getActiveDevices(apartmentId: string): any[] {
    if (USE_DALI) {
      return this.getActiveDevicesWithDali(apartmentId);
    } else {
      return this.getActiveDevicesNoDali(apartmentId);
    }
  }

  getActiveDevicesWithDali(apartmentId){
    const activeDevices = [
      ...this.getActiveLights(apartmentId),
      ...this.getActiveDaliLights(apartmentId),
      ...this.getActiveDaliRgbLights(apartmentId),
      ...this.getActiveHvacs(apartmentId)];
    if (!USE_DEVICE_VISIBLE_TO_USER || User.isAdmin(this.signedInUser)) {
      return activeDevices;
    } else {
      return activeDevices.filter((dev: any) => {
        return dev?.visibleToUser;
      });
    }
  }

  getActiveDevicesNoDali(apartmentId){
    const activeDevices = [
      ...this.getActiveLights(apartmentId),
      ...this.getActiveHvacs(apartmentId)];
    if (!USE_DEVICE_VISIBLE_TO_USER || User.isAdmin(this.signedInUser)) {
      return activeDevices;
    } else {
      return activeDevices.filter((dev: Device) => {
        return dev?.visibleToUser;
      });
    }
  }

  getActiveLights(apartmentId: string): Device[] {
      let lights: Device[] = this.project?.listOfLights;
      lights = this.filterDeviceWithoutAdminProp(lights);
      const activeLights =  lights?.filter((light: Device) => {
        const primaryProperty: Property = this.getPrimaryProperty(light);
        return (Number (primaryProperty?.value) === 1) && this.deviceIsInLocation(light, apartmentId);
      });
      return activeLights ? activeLights : [];
  }

  getActiveHvacs(apartmentId: string): Device[] {
      let  hvacs: Device[] = this.project?.listOfHvacs;
      hvacs =  this.filterDeviceWithoutAdminProp(hvacs);
      const activeHvacs =  hvacs?.filter((hvac: Hvac) => {
        return this.isHvacActive(hvac) && this.deviceIsInLocation(hvac, apartmentId);
         });
      return activeHvacs ? activeHvacs : [];
  }

  getActiveDaliLights(apartmentId: string): Device[] {
     if (this.daliProject) {
        const daliLights: Device[] = this.daliProject.listOfLights;
        return daliLights?.filter((l) => {
          return Number(l.equipmentProperties[0].value > 0) && this.deviceIsInLocation(l, apartmentId);
    });
     } else {
       return [];
     }
  }

  getActiveDaliRgbLights(apartmentId: string): RgbLight[] {
    if (this.daliProject) {
       const daliRgbLights: RgbLight[] = this.daliProject?.listOfRgbs;
       return daliRgbLights?.filter((rgb: any) => {
         return rgb.value !== '#000000|0' && this.deviceIsInLocation(rgb, apartmentId);
   });
    } else {
      return [];
    }
 }

 deviceIsInLocation(device: Device, locationId: string): boolean {
  return device.listOfLocationIds.some((deviceLocationId) => {
    const deviceBuilding = deviceLocationId.split('.')[0];
    const deviceApartment = deviceLocationId.split('.')[1];
    const locationBuilding = locationId.split('.')[0];
    const locationApartment = locationId.split('.')[1];
    return deviceBuilding === locationBuilding && deviceApartment === locationApartment;
  });
 }


  isHvacActive(hvac: Hvac) {
    return hvac.equipmentProperties.some((prop: Property) => {
      return Property.isHvacControl(prop) && Number(prop.value) === 1;
    });
  }

  onClickDevice(activeDevice) {
    if (this.deviceIsLight(activeDevice)) {
      this.onClickLight(activeDevice);
    } else if (this.deviceIsHvac(activeDevice)) {
      this.onClickHvac(activeDevice);
    } else if (this.deviceIsDaliLight(activeDevice)) {
      this.onClickDaliLight(activeDevice);
    } else if (this.deviceIsDaliRgbLight(activeDevice)) {
      this.onClickDaliRgbLight(activeDevice);
    }
  }

  onClickLight(light: Device) {
    const primaryLightProp: Property = this.getPrimaryProperty(light);
    if (primaryLightProp.outComm.address === 'none') {
      return;
    }
    if (this.demoService.isDemo()) {
      this.loadingService.addToLoading(light.id + primaryLightProp.id.toString());
      setTimeout(() => {
      this.projectService.changePropertyValueForDemo(light.id, primaryLightProp.type, 0);
       }, 500);
      this.loadingService.removeFromLoading(light.id + primaryLightProp.id.toString());
    } else {
      this.loadingService.addToLoading(light.id + primaryLightProp.id );
      this.apiProjectService.changeProperty(String(light.id), primaryLightProp.type, '0').subscribe();
    }
  }

  onClickDaliLight(daliLight: Device) {
    if (this.demoService.isDemo()) {
      this.loadingService.addToLoading(daliLight.id);
      setTimeout(() => {
      this.daliProjectService.changePropertyValueForDemo(daliLight.id, '0');
       }, 500);
      this.loadingService.removeFromLoading(daliLight.id);
    } else {
      this.apiDaliProjectService.activateDaliLight(daliLight.id, 0).subscribe();
    }
  }

  onClickDaliRgbLight(daliRgbLight: RgbLight) {
    if (this.demoService.isDemo()) {
      this.loadingService.addToLoading(daliRgbLight.id);
      setTimeout(() => {
      this.daliProjectService.changePropertyValueForDemo(daliRgbLight.id, '#000000|0');
       }, 500);
      this.loadingService.removeFromLoading(daliRgbLight.id);
    } else {
      this.apiDaliProjectService.activateRgbLight(daliRgbLight.id, '#000000', 0).subscribe();
    }
  }

  onClickHvac(hvac: Device) {
    this.roomViewService.setDeviceRouteOrigin('home');
    const hvacRoomId = Device.getRoomId(hvac);
    this.router.navigate([`/rooms/${hvacRoomId}/${hvac.id}`]);
  }

  deviceIsHvac(device: Device) {
    return device.id.split('-')[0] === 'HV';
  }

  deviceIsLight(device: Device) {
    return device.id.split('-')[0] === 'L';
  }

  deviceIsDaliLight(device: Device) {
    return device.id.split('-')[0] === 'DL';
  }

  deviceIsDaliRgbLight(device) {
    return device.id.split('-')[0] === 'DRGB';
  }

  getEnd(activeDevicesLength: number): number {
    return ((activeDevicesLength > 5) ? 4 : 5);
  }

  returnModeTypeAndStatus(mode: Mode) {
    if (mode.actions.name === 'SomeoneInHouse') {
      if (mode.isActive) {
        return 'i-mode-sih-active';
      } else {
        return 'i-mode-sih-inactive';
      }
    } else if (mode.actions.name === 'Arm') {
      if (mode.isActive) {
        return 'i-mode-arm-active';
      } else {
        return 'i-mode-arm-inactive';
      }
    }
  }

  filterDeviceWithoutAdminProp(devices: Device[]): Device[] {
    return devices?.filter( device => {
      return device?.equipmentProperties?.some( prop => {
        return this.equipmentPropertyTypesService.getEquipmentPropertyType(prop.type)
        || this.equipmentPropertyTypesService.getEquipmentPropertyTypeForStatus(prop.type);
      });
    });
  }

  getPrimaryProperty(device: Device): Property {
    return  device.equipmentProperties.find((prop: Property) => {
      const propData = this.equipmentComponentGen.getTextualRepresentationOfValue(prop);
      return propData.primaryProperty === true;
    });
  }

  isDeviceLoading(device: Device) {
    if (this.deviceIsLight(device)) {
      return this.loadingElements.includes(device.id + this.getPrimaryProperty(device).id);
    } else if (this.deviceIsDaliLight(device)) {
      return this.loadingElements.includes(device.id);
    } else if (this.deviceIsDaliRgbLight(device)) {
      return this.loadingElements.includes(device.id);
    }else {
      return false;
    }
  }

  getRoomForDevice(device: Device) {
    const roomId = Device.getRoomId(device);
    return this.projectService.getRoom(roomId);
  }

  getDeviceName(device: Device) {
    if (this.deviceIsLight(device)) {
      return device.equipmentProperties[0].name;
    } else {
      return device.name;
    }
  }

  isTabletLandscape() {
    return this.platform.isLandscape() && this.platform.is('tablet');
  }

  ngOnDestroy(): void {
    if (this.daliProjectSubscription) {
      this.daliProjectSubscription.unsubscribe();
    }
    if (this.sceneSubscription) {
      this.sceneSubscription.unsubscribe();
    }
    if (this.modesSubsctiption) {
      this.modesSubsctiption.unsubscribe();
    }
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
    if (this.backButtonSubscription) {
      this.backButtonSubscription.unsubscribe();
    }
    if (this.projectSubscription) {
      this.projectSubscription.unsubscribe();
    }

  }
}
