import { Pipe, PipeTransform } from '@angular/core';
import { UserSettingsService } from 'src/app/core/services/user-settings.service';

@Pipe({
    name: 'genComTranslate'
})
export class GenComTranslatePipe implements PipeTransform {

    wordPairs = [
    { en: 'On', hr: 'Uključeno' },
    { en: 'Off', hr: 'Isključeno' },
    { en: 'Moving', hr: 'Pokreću se' },
    { en: 'Not moving', hr: 'Ne pokreću se' },
    { en: 'Opened', hr: 'Otvoreno' },
    { en: 'Low', hr: 'Nisko' },
    { en: 'Mid', hr: 'Srednje' },
    { en: 'High', hr: 'Visoko' },
    { en: 'Auto', hr: 'Auto' },
    { en: 'Medium', hr: 'Srednje' },
    { en: 'Alarm', hr: 'Alarm' },
    { en: 'Normal', hr: 'Normalno' },
    { en: 'Heating', hr: 'Grijanje' },
    { en: 'Cooling', hr: 'Hlađenje' },
    { en: 'Open', hr: 'Otvoreno' },
    { en: 'Closed', hr: 'Zatvoreno' },
    { en: 'Close', hr: 'Zatvori' },
    { en: 'Locked', hr: 'Zaključano' },
    { en: 'Unlocked', hr: 'Otključano' },
    { en: 'Bathroom', hr: 'Kupaona' },
    { en: 'Time', hr: 'Vrijeme' },
    { en: 'Values', hr: 'Vrijednosti' },
    { en: 'Blinds', hr: 'Žaluzine' },
    { en: 'Blinds up', hr: 'Podigni žaluzine' },
    { en: 'Blinds down', hr: 'Spusti žaluzine' },
    { en: 'Power', hr: 'Snaga' },
    { en: 'Hvac control', hr: 'Kontrola hvac' },
    { en: 'to', hr: 'je' },
    { en: 'than', hr: 'od' },
    { en: 'from', hr: 'od' },
    { en: 'Equal', hr: 'Jednaki' },
    { en: 'Less', hr: 'Manji' },
    { en: 'Greater', hr: 'Veći' },
    { en: 'Less or equal', hr: 'Manji ili jednaki' },
    { en: 'Greater or equal', hr: 'Veći ili jednaki' },
    { en: 'In range', hr: 'U dometu' },
    { en: 'In range lower included', hr: 'U dometu s manjim uključenim' },
    { en: 'In range upper included', hr: 'U dometu s većim uključenim' },
    { en: 'In range both included', hr: 'U dometu s oba uključena' },
    { en: 'Press back button again to exit app.', hr: 'Opet pritisnite nazad da bi izašli iz aplikacije.' },
    { en: 'Enter ABAS code', hr: 'Unesite ABAS kod' },
    { en: 'Cancel', hr: 'Odustani' },
    { en: 'Enter', hr: 'Prihvati' },
    { en: 'Please wait...', hr: 'Molimo Vas pričekajte...' },
    { en: 'Invalid ABAS code. Please try again.', hr: 'Netočan ABAS kod. Molimo Vas pokušajte ponovno.' },
    { en: 'Error connecting to ABAS server', hr: 'Dogodila se greška u spajanju na ABAS server.' },
    { en: 'Acknowledge all alarms?', hr: 'Potvrdite sve alarme?' },
    { en: 'Enter confirmation text', hr: 'Unesite potvrdni tekst' },
    { en: 'Acknowledge alarm?', hr: 'Potvrdite alarm?' },
    { en: 'Your session has expired. Please login again to continue', hr: 'Vaša sesija je istekla. Molimo Vas prijavite se ponovno da biste nastavili.' },
    { en: 'Wrong username or password', hr: 'Netočno korisničko ime ili lozinka' },
    { en: 'Pin you entered is wrong!', hr: 'Uneseni pin je netočan!' },
    { en: 'No connection', hr: 'Nema konekcije' },
    { en: 'Invalid data', hr: 'Nevažeći podaci' },
    { en: 'Access denied!', hr: 'Pristup odbijen!' },
    { en: 'Access denied. Please login', hr: 'Pristup odbijen. Molimo Vas prijavite se' },
    { en: 'Confirm delete', hr: 'Potvrdi brisanje' },
    { en: 'Delete access pin', hr: 'Brisanje pristupnog pina' },
    { en: 'Access pin deleted', hr: 'Pristupni pin izbrisan' },
    { en: 'Access pin added', hr: 'Dodan pristupni pin' },
    { en: 'Access pin updated', hr: 'Pristupni pin ažuriran' },


    { en: 'Confirm', hr: 'Potvrdi' },
    { en: 'Delete', hr: 'Izbriši' },
    { en: 'Delete preset', hr: 'Izbriši postavke' },
    { en: 'And', hr: 'I' },
    { en: 'Or', hr: 'Ili' },
    { en: 'And not', hr: 'I ne' },
    { en: 'Not affecting', hr: 'Ne utječe' },
    { en: 'Activate mode', hr: 'Aktiviraj režim rada' },
    { en: 'Deactivate mode', hr: 'Deaktiviraj režim rada' },
    { en: 'Automation is not editable', hr: 'Automatizacija nije editabilna' },
    { en: 'Delete automation', hr: 'Izbriši automatizaciju' },
    { en: 'Discard', hr: 'Odbaci' },
    { en: 'Delete mode', hr: 'Izbriši režim rada' },
    { en: 'Discarding changes', hr: 'Odbaci promjene' },
    { en: 'Discard', hr: 'Odbaci' },
    { en: 'Delete scene', hr: 'Izbriši scenu' },
    { en: 'Delete alarm subscription?', hr: 'Izbrisati pretplatu na alarm?' },
    { en: 'Delete configuration and return to landing page?', hr: 'Izbrisati konfiguraciju i vratiti se na početak?' },
    { en: 'Deleting configuration...', hr: 'Brišem konfiguraciju...' },
    { en: 'Delete user', hr: 'Izbriši korisnika' },
    { en: 'is equal to ', hr: 'je jednak ' },
    { en: 'is less than ', hr: 'je manji od ' },
    { en: 'is greater than ', hr: 'je veći od ' },
    { en: 'is less or equal to ', hr: 'je manji ili jednak s ' },
    { en: 'is greater or equal to ', hr: 'je veći ili jednak s ' },
    { en: 'is in range between ', hr: 'je u dometu ' },
    { en: 'is equal or greater than ', hr: 'je jednak ili veći od ' },
    { en: ' and less than ', hr: ' i manji od ' },
    { en: ' and less or equal to ', hr: ' i manji ili jednak s ' },
    { en: ' and ', hr: ' i ' },
    { en: 'Yes', hr: 'Da' },
    { en: 'No', hr: 'Ne' },
    { en: 'Heating', hr: 'Grijanje' },
    { en: 'Cooling', hr: 'Hlađenje' },
    { en: 'Someone in house', hr: 'Netko je u kući' },
    { en: 'Custom', hr: 'Prilagodi' },
    { en: 'Light', hr: 'Svjetlo' },
    { en: 'Status', hr: 'Stanje' },
    { en: 'Set light to', hr: 'Postavi jačinu na' },
    { en: 'Switch mode', hr: 'Promjeni režim rada' },
    { en: 'Confirm switch mode?', hr: 'Potvrdi promjenu režima rada?' },
    { en: 'Turn on cooling mode?', hr: 'Upali režim rada - hlađenje?' },
    { en: 'Turn on heating mode?', hr: 'Upali režim rada - grijanje?' },
    { en: 'Configuration code:', hr: 'Konfiguracijski kod:' },
    { en: 'Touchpad', hr: 'Tipkalo' },
    { en: 'Switch', hr: 'Prekidač' },
    { en: 'Scene', hr: 'Scena' },
    { en: 'turned on', hr: 'uključena' },
    { en: 'turned off', hr: 'isključena' },
    { en: 'Configuration updated. Restart app to apply changes.', hr: 'Ažurirana konfiguracija. Za primjenu promjena ponovno pokrenite aplikaciju.'},
    { en: 'Dismiss', hr: 'Zatvori' },
    { en: 'Device', hr: 'Uređaj' },
    { en: 'added', hr: 'dodan' },
    { en: 'Access pin reserved. Choose another pin!', hr: 'Pin za pristup je rezerviran. Odaberite drugi pin!' },
    { en: 'Access pin', hr: 'Pristupni pin' },
    { en: 'Return to landing page', hr: 'Povratak na početnu stranicu' }
    ];

    constructor(private userSettingsService: UserSettingsService) { }
    transform(value: string): string {
        if (this.userSettingsService.getLanguage() === 'hr') {
            const target = this.wordPairs.find(element => {
                if (element.en === value) { return true; }
            });
            if (target) {
                return target.hr;
            } else {
                return value;
            }
        } else {
            return value;
        }
    }
}
