<div class="flex-column-center login-full-screen">
  <div *ngIf="!isLoggedIn() && forgotPassword === false" class="width-90p">
    <div class="white text-2 margin-top20">ABAS</div>
    <div class="margin-top30 text-1">{{ "login" | translate }}</div>
    <form
      (ngSubmit)="onLogin(loginForm)"
      #loginForm="ngForm"
      class="flex-column-center margin-top50"
    >
      <div class="content-form-group">
        <label class="margin-bottom5" for="email"
          >{{ "email" | translate }}:</label
        >
        <input
          type="text"
          class="form-control"
          id="email"
          placeholder="{{ 'enter_username' | translate }}"
          [(ngModel)]="user.email"
          [ngClass]="{
            'is-invalid':
              loginForm.controls.email?.errors != null && loginForm.submitted
          }"
          name="email"
          required
          email
        />
      </div>
      <div
        class="invalid-input"
        *ngIf="loginForm.controls.email?.errors != null && loginForm.submitted"
      >
        {{ "valid_email" | translate }}
      </div>
      <div class="content-form-group">
        <label for="password">{{ "password" | translate }}:</label>
        <input
          type="password"
          class="form-control"
          id="password"
          placeholder="{{ 'enter_password' | translate }}"
          [(ngModel)]="user.password"
          [ngClass]="{
            'is-invalid':
              loginForm.controls.password?.errors != null && loginForm.submitted
          }"
          name="password"
          required
        />
      </div>
      <div
        class="invalid-input"
        *ngIf="
          loginForm.controls.password?.errors != null && loginForm.submitted
        "
      >
        {{ "enter_password" | translate }}
      </div>
      <ion-button
        type="submit"
        size="default"
        class="square-secondary login-btn margin-top20"
        [disabled]="loading"
      >
        {{ "login" | translate }}
      </ion-button>
      <div
        class="sublink clickable margin-top20 margin-bottom20 white"
        (click)="forgotPassword = true"
      >
        {{ "forgot_password" | translate }}?
      </div>
    </form>
  </div>

  <div
    class="width-90p"
    *ngIf="!isLoggedIn() && forgotPassword && !passwordHasReset"
  >
    <div class="margin-top30 text-1">{{ "forgot_password" | translate }}?</div>
    <div class="margin-top100 new-password">
      {{ "new_password" | translate }}
    </div>
    <form
      class="flex-column flex-center"
      (ngSubmit)="onResetPassword(resetPassForm)"
      #resetPassForm="ngForm"
    >
      <div class="content-form-group">
        <input
          type="text"
          class="form-control"
          id="emailForgotPassword"
          placeholder="{{ 'enter_email' | translate }}"
          name="emailForgotPassword"
          [(ngModel)]="emailForgotenPassword"
          email
          required
          [ngClass]="{
            'is-invalid':
              resetPassForm.controls.emailForgotPassword?.errors != null &&
              resetPassForm.submitted
          }"
        />
      </div>
      <div
        class="invalid-input"
        *ngIf="
          resetPassForm.controls.emailForgotPassword?.errors != null &&
          resetPassForm.submitted
        "
      >
        {{ "valid_email" | translate }}
      </div>
      <ion-button
        type="submit"
        size="default"
        class="square-secondary login-btn margin-top20"
        >{{ "reset_password" | translate }}</ion-button
      >
      <div class="sublink clickable margin-top20 white" (click)="backToLogin()">
        {{ "back_to_login" | translate }}
      </div>
    </form>
  </div>

  <div
    class="width-90p"
    *ngIf="!isLoggedIn() && forgotPassword && passwordHasReset"
  >
    <div class="margin-top30 text-1">{{ "password_reset" | translate }}</div>
    <div class="margin-top160 new-password">
      {{ "password_to_mail" | translate }}
    </div>
    <div class="flex-row-center-full margin-top50" (click)="backToLogin()">
      <div class="i-back-arrow"></div>
      <div class="sublink clickable white margin-left20">
        {{ "back_to_login" | translate }}
      </div>
    </div>
  </div>

  <div *ngIf="isLoggedIn()" class="flex-column flex-center margin-top160">
    <h1 class="white">{{ "user_logged_in" | translate }}</h1>
    <h4 class="white" (click)="continue()">{{ "continue" | translate }}</h4>
  </div>
  <div
    *ngIf="platform.is('mobile') && !isLoggedIn()"
    class="triangle-bottom-left"
    (click)="openDeleteConfigModal()"
  >
    <div class="i-forward-arrow rotate180 margin-top15"></div>
  </div>
</div>
