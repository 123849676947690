import { Injectable } from '@angular/core';
import { UserSettings } from 'src/app/core/models/user-settings.model';
import { UserLanguage } from 'src/app/core/models/user-language.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class UserSettingsService {

  scrollwatch$ = new Subject<boolean>()
  defaultUserSettings: UserSettings = {
    roomViewType: 'allDevices'
  };

  defaultUserLanguage: UserLanguage = {
    language: 'en'
  };

  constructor() {}

  getRoomViewTypeSetting(): 'allDevices' | 'rooms' {
    const userSettingsStorage: string = localStorage.getItem('abas_user_settings');
    if (userSettingsStorage) {
      const abasUserSettings: UserSettings = JSON.parse(userSettingsStorage);
      return abasUserSettings.roomViewType;
    } else {
      localStorage.setItem('abas_user_settings', JSON.stringify(this.defaultUserSettings));
      return this.defaultUserSettings.roomViewType;
    }
  }

  setRoomViewTypeSetting(newViewType: 'allDevices' | 'rooms') {
    const userSettingsStorage: string = localStorage.getItem('abas_user_settings');
    if (userSettingsStorage) {
      const newAbasUserSettings: UserSettings = JSON.parse(userSettingsStorage);
      newAbasUserSettings.roomViewType = newViewType;
      localStorage.setItem('abas_user_settings', JSON.stringify(newAbasUserSettings));
    } else {
      const newAbasUserSettings: UserSettings = this.defaultUserSettings;
      newAbasUserSettings.roomViewType = newViewType;
      localStorage.setItem('abas_user_settings', JSON.stringify(newAbasUserSettings));
    }
  }


  getLanguage(): 'en' | 'hr' {
    const userLanguage: string = localStorage.getItem('abas_user_language');
    if (userLanguage) {
      const abasUserLanguage: UserLanguage = JSON.parse(userLanguage);
      return abasUserLanguage.language;
    } else {
      localStorage.setItem('abas_user_language', JSON.stringify(this.defaultUserLanguage));
      return this.defaultUserLanguage.language;
    }
  }

  setLanguage(newLanguage: 'en' | 'hr') {
    const userLanguage: string = localStorage.getItem('abas_user_language');
    if (userLanguage) {
      const newUserLanguage: UserLanguage = JSON.parse(userLanguage);
      newUserLanguage.language = newLanguage;
      localStorage.setItem('abas_user_language', JSON.stringify(newUserLanguage));
    } else {
      const newUserLanguage: UserLanguage = this.defaultUserLanguage;
      newUserLanguage.language = newLanguage;
      localStorage.setItem('abas_user_language', JSON.stringify(newUserLanguage));
    }
  }

}
