import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment, setDefaultPinDurationInDays, setUseDeviceVisibleToUser } from 'src/environments/environment';
import { MqttService } from 'ngx-mqtt';
import { Platform, ToastController } from '@ionic/angular';
import { File } from '@ionic-native/file/ngx';
import { NetworkService } from '../services/network.service';
import { Router } from '@angular/router';
import { timeout } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';
import { MqttProjectService } from './mqtt-project.service';

import { setUseDali } from 'src/environments/environment';
import { ApiConfigProviderService, ConfigProviderOutput } from './api-config-provider.service';
import { GenComTranslatePipe } from 'src/app/shared/pipes/gen-com-translate.pipe';
import { Configuration } from '../models/configuration.model';
import { lastValueFrom } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ConfigLoaderService {

  private readonly CONFIG_URL = 'assets/config/config.json';
  private readonly PROD_CONFIG_URL = '../config/config.json';
  private fullConfig: ConfigProviderOutput;

  nativeConfigDirName = 'ABAS';
  nativeConfigDirParentLocation: string;
  nativeConfigFileName = 'abasConfig.json';
  iosPrefix = 'capacitor://localhost/_capacitor_file_';
  androidPrefix = 'http://localhost/_capacitor_file_';
  prefix: string;
  localNetworkPingTimeout = 2000;

  checkedConfigForUpdates = false;

  constructor(private http: HttpClient,
              private platform: Platform,
              private file: File,
              private networkService: NetworkService,
              private router: Router,
              private authenticationService: AuthenticationService,
              private mqttProjectService: MqttProjectService,
              private mqttService: MqttService,
              private apiConfigProviderService: ApiConfigProviderService,
              private pipe: GenComTranslatePipe,
              private toastController: ToastController
              ) {}


  setConfig(fullConfig: ConfigProviderOutput) {
    this.fullConfig = fullConfig;
  }

  getConfig() {
    return this.fullConfig;
  }

  public async configStartup() {
      await this.platform.ready();
      if (this.platform.is('android')) {
        this.nativeConfigDirParentLocation = this.file.dataDirectory;
        this.prefix = this.androidPrefix;
        await this.loadMobileConfig();
      } else if (this.platform.is('ios')) {
        this.nativeConfigDirParentLocation = this.file.documentsDirectory;
        this.prefix = this.iosPrefix;
        await this.loadMobileConfig();
      } else {
      await this.loadWebConfig();
      }
  }

  private async loadMobileConfig(){
    try {
      this.fullConfig = await this.getConfigFromStorage();
      setUseDali(this.fullConfig.configuration.USE_DALI);
      setUseDeviceVisibleToUser(this.fullConfig.configuration.USE_DEVICE_VISIBLE_TO_USER);
      setDefaultPinDurationInDays(this.fullConfig.configuration.DEFAULT_PIN_DURATION_IN_DAYS);
      try {
        await lastValueFrom(this.http.get(`${this.fullConfig.configuration.API_BASE_LOCAL_URL}/status`, {responseType: 'text'})
        .pipe(timeout(this.localNetworkPingTimeout)));
        await this.localNetworkStartup(this.fullConfig.configuration);
      } catch (err) {
        await this.publicNetworkStartup(this.fullConfig.configuration);
      }
    } catch (error){
      this.router.navigate(['/landing']);
    }
  }

  private async loadWebConfig() {
    let configUrl: string;
    if (environment.production) {
      configUrl = this.PROD_CONFIG_URL;
    } else {
      configUrl = this.CONFIG_URL;
    }
    const webConfig: Configuration = await lastValueFrom(this.http.get<Configuration>(configUrl));
    this.fullConfig = {
      _id: '',
      configuration: webConfig,
      locationId: '',
      type: 'abas'
    };
    setUseDali(this.fullConfig.configuration.USE_DALI);
    setUseDeviceVisibleToUser(this.fullConfig.configuration.USE_DEVICE_VISIBLE_TO_USER);
    setDefaultPinDurationInDays(this.fullConfig.configuration.DEFAULT_PIN_DURATION_IN_DAYS);
    if (document.location.hostname === this.fullConfig.configuration.OVPN_URL) {
      this.publicNetworkStartup(this.fullConfig.configuration);
    } else {
      this.localNetworkStartup(this.fullConfig.configuration);
    }
  }


  private async localNetworkStartup(config: Configuration) {
    this.networkService.setLocalNetwork(config);
    this.networkService.initNetworkSwitcher(config);
    if (this.authenticationService.isValid()) {
      try {
      await lastValueFrom(this.authenticationService.getUserByToken());
      this.mqttProjectService.unsubscribeFromMqtt();
      this.mqttService.connect(config.MQTT_LOCAL_SETTINGS);
      await this.mqttProjectService.initMqttForProject();
      // routes to home automaticaly, no need for router
    } catch (err) {
      console.log(err);
    }
    } else {
      this.router.navigate(['/login']);
    }
  }


  private async publicNetworkStartup(config: Configuration) {
    console.log('public network startup');
    this.networkService.setPublicNetwork(config);
    this.networkService.initNetworkSwitcher(config);
    if (this.authenticationService.isValid()) {
      await lastValueFrom(this.authenticationService.getUserByToken());
      this.mqttProjectService.unsubscribeFromMqtt();
      this.mqttService.connect(config.MQTT_OVPN_SETTINGS);
      await this.mqttProjectService.initMqttForProject();
      // routes to home automaticaly, no need for router
    } else {
      this.router.navigate(['/login']);
    }
  }

  async saveConfigToStorage(fullConfig: ConfigProviderOutput) {
    try {
      await this.file.createDir(this.nativeConfigDirParentLocation, `${this.nativeConfigDirName}`, true);
      await this.file.writeFile(
        `${this.nativeConfigDirParentLocation}${this.nativeConfigDirName}`,
        this.nativeConfigFileName,
        JSON.stringify(fullConfig),
        {replace: true}
      );
    }
    catch (err){
      console.log(err);
    }
  }

  async getConfigFromStorage(): Promise<ConfigProviderOutput> {
    await this.file.checkDir(`${this.nativeConfigDirParentLocation}`, this.nativeConfigDirName);
    await this.file.checkFile(`${this.nativeConfigDirParentLocation}${this.nativeConfigDirName}/`, this.nativeConfigFileName);
    // const fullpath = await this.file.resolveLocalFilesystemUrl(`${this.configDirParentLocation}${this.configDirName}/${this.fileName}`)
    // this.file.readAsText on Cordova file plugin does not work, so http.get had to be used,
    //
    // const path = this.webview.convertFileSrc(`${this.configDirParentLocation}abas/${this.fileName}`)
    // cordova-webview  plugin has convertFileSrc() function which gets correct path for http call but to avoid using another plugin
    // local implementation of function is used
    // incorect path for http call 'file:///storage/emulated/0/abas/newFile.txt'
    // correct path `http://localhost/_capacitor_file_/storage/emulated/0/abas/newFile.txt`
    const split = `${this.nativeConfigDirParentLocation}${this.nativeConfigDirName}/${this.nativeConfigFileName}`.split('//', 2)[1];
    const path = `${this.prefix}${split}`;
    return await lastValueFrom(this.http.get<ConfigProviderOutput>(path));
  }

  async deleteConfigFromStorage() {
    try {
      await this.file.removeFile(
        `${this.nativeConfigDirParentLocation}${this.nativeConfigDirName}`,
        this.nativeConfigFileName);
    }
    catch (err){
      console.log(err);
    }
  }

  checkConfigForUpdates() {
    if (!this.checkedConfigForUpdates) {
      this.checkedConfigForUpdates = true;
      this.apiConfigProviderService.getConfig(this.fullConfig._id)
      .pipe(timeout(5000))
      .subscribe({
        next: (freshConfig: ConfigProviderOutput) => {
          if (Object.keys(freshConfig).length === 0) { // check for empty object
            return;
          }
          if (JSON.stringify(this.fullConfig) !== JSON.stringify(freshConfig)) {
            this.saveConfigToStorage(freshConfig);
            this.presentToast('Configuration updated. Restart app to apply changes.');
            console.log(`Configuration updated. Restart app to apply changes.`);
          } else {
            console.log(`No changes to configuration.`);
          }
        },
        error: (err) => {
          console.log(`Configuration server not available. ${JSON.stringify(err)}`);
        }
      });
    }
  }

  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: this.pipe.transform(msg),
      // duration: 2000,
      buttons: [
       {
          text: this.pipe.transform('Dismiss'),
          role: 'cancel',
          /* handler: () => {
            console.log('Cancel clicked');
          } */
        }
      ]

    });
    toast.present();
  }

}
