import { Component, OnInit, OnDestroy, HostListener, Optional } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { User } from '../../../core/models/user/user.model';
import { UserService } from './../../../core/services/user.service';
import { enterAnimation } from '../../animations/animations';
import { AlarmAlertsService } from 'src/app/modules/alarms/services/alarm-alerts.service';
import { AlarmAlert } from '../../../core/models/alarms/alarm-alert.model';
import { Project } from 'src/app/core/models/project/project.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { Apartment } from 'src/app/core/models/project/apartment.model';
import { ApartmentService } from 'src/app/shared/services/apartment.service';
import { LoadingService } from '../../services/loading.service';
import { ApiAlarmAlertsService } from 'src/app/modules/alarms/services/http/api-alarm-alerts.service';
import { DemoService } from 'src/app/core/services/demo.service';
import { Platform } from '@ionic/angular';
import { SleepService } from '../../services/sleep.service';
import { ApiDaliProjectService } from 'src/app/modules/project/services/http/api-dali-project.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  animations: [enterAnimation],
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  user: User;
  userSubscription: Subscription;
  cardReaderConnected: boolean;
  cardReaderConnectedSubscription: Subscription;
  dropdownOpened = false;
  alarms: AlarmAlert[] = [];
  alarmsSubscription: Subscription;
  alarmsInit: boolean;

  project: Project = this.projectService.getProject();
  apartments: Apartment[];
  projectSubscription: Subscription;
  buildingClicked = false;
  apartmantName: string;

  selectedApartmentSubscription: Subscription;

  loadingSubscription: Subscription;
  loadingElements = [];

  alarmAlertsSub: Subscription;

  getAlarmsParams = {
    Filtering: '',
    Sorting: '',
    DateTimeFrom: '',
    DateTimeTo: '',
    Levels: [],
    Types: [],
    Confirmed: [0],
    ConfirmUsers: []
  };
  resumeSubscription: Subscription;

  constructor(private authService: AuthenticationService,
              private userService: UserService,
              private api: AuthenticationService,
              private projectService: ProjectService,
              private apiProjectService: ApiProjectService,
              private apartmentService: ApartmentService,
              private loadingService: LoadingService,
              private apiAlarmAlertsService: ApiAlarmAlertsService,
              private demoService: DemoService,
              private platform: Platform,
              private sleepService: SleepService,
              private apiDaliProjectService: ApiDaliProjectService,
              @Optional() private alarmAlertsService?: AlarmAlertsService,
              ) { }

  ngOnInit() {
    this.resumeSubscription = this.platform.resume.subscribe(() => {
      this.apiAlarmAlertsService.getUnconfirmedAlarms(1, 100, this.getAlarmsParams).subscribe();
      this.apiProjectService.getProject().subscribe();
      this.apiDaliProjectService.getProject().subscribe();
      this.alarmAlertsService.reInitializeAlarmService();
      this.sleepService.activateSleepTimer();
    });

    this.loadingSub();
    if (!this.apartmantName) {
      this.apartmentService.setApartmentId(this.project?.listOfApartments[0]?.id);
    }
    if (this.project?.listOfBuildings?.length === 1) {
      this.onBuildingSelected(this.project.listOfBuildings[0].id);
    }
    this.selectedApartmentSubscription = this.apartmentService.getApartmentId().subscribe((aptId) => {
      this.apartmantName = this.getApartmantName(aptId);
    });

    this.user = Object.assign(new User(), this.userService.getUser());
    if (this.user === undefined) {
      if (window.location.href.includes('home')) {
        this.api.getUserByToken().subscribe();
      }
    }
    this.userSubscription = this.userService.userChanged.subscribe(() => {
      this.user = Object.assign(new User(), this.userService.getUser());
      if (this.user.havePermission(1) && !this.alarmsInit) {
        this.getAlarms();
        this.alarmsInit = true;
      }
    });
    if (this.user.havePermission(1) && !this.alarmsInit) {
      this.getAlarms();
      this.alarmsInit = true;
    }

    if (!this.demoService.isDemo() ){
      this.apiAlarmAlertsService.getUnconfirmedAlarms(1, 100, this.getAlarmsParams).subscribe();
    }
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
      this.apiAlarmAlertsService.getUnconfirmedAlarms(1, 100, this.getAlarmsParams).subscribe();
  }

  loadingSub() {
    this.loadingSubscription = this.loadingService.loadingChanged.subscribe( response => {
      this.loadingElements = this.loadingService.getLoadingElements();
    });
  }


  getApartmantName(aptId: string) {
    if (aptId) {
    const apartmentName: Apartment = this.project?.listOfApartments.find((element: Apartment) => element.id === aptId);
    return apartmentName?.name;
    }
  }


  onBuildingSelected(id: string) {
    this.apartments = this.project.listOfApartments.filter( app => app.buildingId === id);
    this.projectService.setBuildingId(id);
    this.buildingClicked = true;
  }

  onApartmentSelected(id: string) {
    this.apartmentService.selectApartment(id);
    if (this.project?.listOfBuildings?.length !== 1 && this.project?.listOfBuildings?.length !== 0) {
      this.buildingClicked = false;
    }
    this.dropdownOpened = false;
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    const el = event.target as HTMLElement;
    if (!el.classList.contains('dropdown-title-text') && !el.classList.contains('header-title') && !el.classList.contains('header-arrow')
      && !el.classList.contains('header-dropdown') && !el.classList.contains('header-user')
       && !el.classList.contains('header-link-building') && !el.classList.contains('header-location')) {
        if (this.project?.listOfBuildings?.length !== 1 && this.project?.listOfBuildings?.length !== 0) {
          this.buildingClicked = false;
        }
      if (this.project?.listOfBuildings?.length === 1) {
        this.onBuildingSelected(this.project.listOfBuildings[0].id);
      }
      this.dropdownOpened = false;
    }
  }

  getAlarms() {
    this.alarms = this.alarmAlertsService.getAlarms();
    this.alarmsSubscription = this.alarmAlertsService.alarmsChanged.subscribe(alarms => {
      this.alarms = this.alarmAlertsService.getAlarms();
    });
  }

  get unconfirmedAlerts() {
    return this.alarms.reduce((r, a) => {
      return r + +(!a.confirmed);
    }, 0);
  }

  userIsAdmin(user: User): boolean {
    return User.isAdmin(user);
  }
  userIsOwner(user: User): boolean {
    return User.isOwner(user);
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    if (this.cardReaderConnectedSubscription) {
      this.cardReaderConnectedSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.alarmsSubscription) {
      this.alarmsSubscription.unsubscribe();
    }
    if (this.projectSubscription) {
      this.projectSubscription.unsubscribe();
    }
    if (this.selectedApartmentSubscription) {
      this.selectedApartmentSubscription.unsubscribe();
    }
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
    if (this.resumeSubscription) {
      this.resumeSubscription.unsubscribe();
    }

  }

}
