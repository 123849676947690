import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { MqttService, IMqttMessage } from 'ngx-mqtt';

import { ApiService } from 'src/app/core/http/api.service';
import { AlarmAlert } from 'src/app/core/models/alarms/alarm-alert.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { API_BASE_URL } from 'src/environments/environment';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class AlarmAlertsService {
    alarmsInitialized = false;
    mqttBase: string;
    mqttAlarmsSubscription = new Subscription();
    alarms: AlarmAlert[] = [];
    alarmsChanged = new Subject<AlarmAlert[]>();
    alarmAdded = new Subject<AlarmAlert>();

    getAlarmsParams = {
      Filtering: '',
      Sorting: '',
      DateTimeFrom: '',
      DateTimeTo: '',
      Levels: [],
      Types: [],
      Confirmed: [0],
      ConfirmUsers: []
    };

    constructor(private http: HttpClient,
                private api: ApiService,
                private mqttService: MqttService,
                // private router: Router,
                private toastController: ToastController
                ) {}

    getAlarms(): AlarmAlert[] {
        return this.alarms;
    }

    setAlarms(alarms: AlarmAlert[]) {
        this.alarms = alarms;
        this.alarmsChanged.next(this.alarms);
    }

    addAlarm(alarm: AlarmAlert) {
        this.alarms.push(alarm);
        this.alarmAdded.next(alarm);
        this.alarmsChanged.next(this.alarms);
    }

    updateAlarm(i: number, alarm: AlarmAlert) {
        this.alarms[i] = alarm;
        this.alarmsChanged.next(this.alarms);
    }

    confirmAlarm(id: number) {
        this.alarms = this.alarms.filter( element => element.alarmLogId !== id);
        this.alarmsChanged.next(this.alarms);
    }

    initAlarmsService() {
        if (this.alarmsInitialized) {
            return;
        }
        this.api.getBaseMqttSubscription().subscribe(mqttBase => {
                this.mqttBase = mqttBase;
                this.subscribeToMqttAlarms();
            });
        this.alarmsInitialized = true;
    }

    reInitializeAlarmService() {
      if (this.alarmsInitialized) {
        this.unsubscribeFromMqttAlarms();
        this.subscribeToMqttAlarms();
        this.alarmsInitialized = true;
      }

    }



    subscribeToMqttAlarms() {
        this.mqttAlarmsSubscription = this.mqttService.observe(`${this.mqttBase}/alarm/+`).subscribe((message: IMqttMessage) => {
            this.presentToast(message);
            const params = new HttpParams()
            .set('pagenumber', '1')
            .set('pagesize', '100');
            this.http.post<any>(API_BASE_URL + '/alarms',  this.getAlarmsParams, {params}).pipe(
                  map( alarms => {
                    this.setAlarms(alarms);
                    return alarms;
                })).subscribe();

       });
   }

   async presentToast(message: IMqttMessage) {
    const alertLevel = +message.payload.toString().split('|')[2];
    let toastColor = '';
    if (alertLevel === 2) {
        toastColor = 'warning';
    } else if (alertLevel === 3){
        toastColor = 'danger';
    }
    const toast = await this.toastController.create({
      message: message.payload.toString().split('|')[1],
      duration: 2000,
      color: toastColor
    });
    toast.present();
  }

    unsubscribeFromMqttAlarms() {
        this.mqttAlarmsSubscription.unsubscribe();
        this.mqttAlarmsSubscription = new Subscription();
        this.alarmsInitialized = false;
    }
}
