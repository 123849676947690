import { Injectable } from '@angular/core';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { lastValueFrom, Subscription } from 'rxjs';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { Project } from '../models/project/project.model';
import { ApiScenesService } from 'src/app/modules/scenes/api-scenes.service';
import { ApiModesService } from 'src/app/modules/modes/services/http/api-modes.service';
import { ApiAutomationService } from 'src/app/modules/automation/services/http/api-automation.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { DemoService } from '../services/demo.service';
import { ApiDaliProjectService } from 'src/app/modules/project/services/http/api-dali-project.service';
import { DaliProjectService } from 'src/app/modules/project/services/dali-project.service';
import { USE_DALI } from 'src/environments/environment';



@Injectable({
    providedIn: 'root'
  })
export class MqttProjectService {

    project: Project;

    mqttSubscription = new Subscription();
    elementIsFound = false;

    constructor(private mqttService: MqttService,
                private projectService: ProjectService,
                private apiProjectService: ApiProjectService,
                private apiScenesService: ApiScenesService,
                private apiModesService: ApiModesService,
                private apiAutomationService: ApiAutomationService,
                private loadingService: LoadingService,
                private demoService: DemoService,
                private apiDaliProjectService: ApiDaliProjectService,
                private daliProjectService: DaliProjectService) {}

    async initMqttForProject() {
      if (!this.demoService.isDemo()) {
        // console.log('initing mqtt project');
        await this.subscribeToProjectProps();
        this.subscribeToMqttForChanges();
        if (USE_DALI) {
          await this.subscribeToDaliMqtt();
         } else {
          this.apiDaliProjectService.setDaliResolverTrue();
         }
      }
    }

    async subscribeToProjectProps() {
      try {
        let proj: Project = await lastValueFrom(this.apiProjectService.getProject());
        if (!proj) {
          proj = await lastValueFrom(this.apiProjectService.getProject());
        }
      } catch (err) {
        await lastValueFrom(this.apiProjectService.getProject());
      }
      this.project = this.projectService.getProject();
      this.subscribeToEveryProp('listOfBlinds');
      this.subscribeToEveryProp('listOfGeneralEquipment');
      this.subscribeToEveryProp('listOfHvacs');
      this.subscribeToEveryProp('listOfLights');
      this.subscribeToEveryProp('listOfSafetyEquipment');
      this.subscribeToEveryProp('listOfSecurityEquipment');
    }

    getIdOfElementFromTopic(message: IMqttMessage) {
      const indexes = [];
      for (let i = 0; i < message.topic.length; i++) {
        if (message.topic[i] === '/') { indexes.push(i); }
      }
      const changedId =  message.topic.slice(indexes[3] + 1, indexes[4]);
      return changedId;
    }

    subscribeToMqttForChanges() {
      this.mqttSubscription.add(this.mqttService.observe('sys/abas/1/automations/changed').subscribe( (message: IMqttMessage) => {
        this.apiAutomationService.getAutomations().subscribe();
      }));

      this.mqttSubscription.add(this.mqttService.observe('sys/abas/1/automations/+/changed').subscribe( (message: IMqttMessage) => {
        this.apiAutomationService.getAutomations().subscribe();
      }));

      this.mqttSubscription.add(this.mqttService.observe('sys/abas/1/scenes/changed').subscribe( (message: IMqttMessage) => {
        this.apiScenesService.getScenes().subscribe();
      }));

      this.mqttSubscription.add(this.mqttService.observe('sys/abas/1/scenes/+/changed').subscribe( (message: IMqttMessage) => {
        const changedId = this.getIdOfElementFromTopic(message);
        this.apiScenesService.getScene(changedId).subscribe();
      }));

      this.mqttSubscription.add(this.mqttService.observe('sys/abas/1/modes/changed').subscribe( (message: IMqttMessage) => {
        this.apiModesService.getModes().subscribe();
      }));

      this.mqttSubscription.add(this.mqttService.observe('sys/abas/1/modes/+/changed').subscribe( (message: IMqttMessage) => {
        const changedId = this.getIdOfElementFromTopic(message);
        this.apiModesService.getMode(changedId).subscribe();
      }));
    }

    subscribeToEveryProp(arrayName: string) {
      this.project[arrayName].forEach(element => {
        element.equipmentProperties.forEach( prop => {
          this.subscribeToMqtt(prop.inComm.address);
        });
      });
    }

    checkEveryPropForChange(arrayName: string, topic: string, payload: string) {
      this.project[arrayName].some(element => {
        const found = element.equipmentProperties.find( prop => prop.inComm.address === topic);
        if (found) {
          if (found.id !== '123' && found.id !== '122')
          {

          }
          found.value = payload;
          this.elementIsFound = true;
          this.loadingService.removeFromLoading(element.id + found.id);
          this.projectService.updateProjectByMqtt(arrayName, found, element.id);
          this.projectService.updateGeneratedComponentByMqtt(found);
          return true;
        }
      });
    }

    subscribeToMqtt(address: string) {
      this.mqttSubscription.add(this.mqttService.observe(address).subscribe( (message: IMqttMessage) => {
        const index = message.payload.toString().lastIndexOf('|');
        const payload = message.payload.toString().slice(index + 1);
        this.elementIsFound = false;
        if (!this.elementIsFound) {this.checkEveryPropForChange('listOfBlinds', message.topic, payload); }
        if (!this.elementIsFound) {this.checkEveryPropForChange('listOfGeneralEquipment', message.topic, payload); }
        if (!this.elementIsFound) {this.checkEveryPropForChange('listOfHvacs', message.topic, payload); }
        if (!this.elementIsFound) {this.checkEveryPropForChange('listOfLights', message.topic, payload); }
        if (!this.elementIsFound) {this.checkEveryPropForChange('listOfSafetyEquipment', message.topic, payload); }
        if (!this.elementIsFound) {this.checkEveryPropForChange('listOfSecurityEquipment', message.topic, payload); }
      }));
      }

    async subscribeToDaliMqtt() {
      try {
        let proj = await lastValueFrom(this.apiDaliProjectService.getProject())
        if (!proj) {
          proj = await lastValueFrom(this.apiDaliProjectService.getProject())
        }
      } catch (err) {
        await lastValueFrom(this.apiDaliProjectService.getProject())
      }
      this.apiDaliProjectService.getBaseDaliMqttSubscription().subscribe((daliMqttBase) => {
        this.mqttSubscription = this.mqttService.observe(`${daliMqttBase}/#`).subscribe((message: IMqttMessage) => {
          console.log(message.topic, message.payload.toString());
          const daliType = message.topic.split('/')[2];
          const id = message.topic.split('/')[4];
          let daliId: string;
          if (daliType === 'light') {
            daliId = `DL-${id}`;
          } else if (daliType === 'rgb') {
            daliId = `DRGB-${id}`;
          } else if (daliType === 'scene') {
            daliId = `DS-${id}`;
          }
          this.daliProjectService.updateDaliProjectByMqtt(daliType, daliId, message.payload.toString());
          this.loadingService.removeFromLoading(daliId);
        });
      });
    }

    unsubscribeFromMqtt(){
      // console.log('unsubing');
      this.mqttSubscription.unsubscribe();
      this.mqttSubscription = new Subscription();
    }
}
