import { IMqttServiceOptions } from 'ngx-mqtt';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};


export let ENVIRONMENT_MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: 'mqtt.ae.hr',
  port: 8083,
  path: '/mqtt',
  protocol: 'ws',
  connectOnCreate: false
};

export let API_BASE_URL = 'http://10.0.2.20:2806';
// export let API_BASE_URL = 'https://abasdemo.ae.hr/api/abasdemo/1/demo';
export let VERSION_CHECK_URL = 'http://10.0.2.20:82/version.json';
export let CONFIG_PROVIDER_URL = 'https://configmanager.ae.hr';
export let USE_DALI = false;
export let SLEEP_TIMEOUT = 0;
export let USE_DEVICE_VISIBLE_TO_USER = false;
export let DEFAULT_PIN_DURATION_IN_DAYS = 0;

export const demoUser = {
  apartmentIds: ['0.0'],
  email : 'demo@ae.hr',
  firstName: 'Demo',
  fullName: 'Demo Demo',
  lastName: 'Demo',
  password: null,
  passwordHash: null,
  passwordSalt: null,
  pin: null,
  pinHash: null,
  pinSalt: null,
  refreshTokens: null,
  roleLevel: 4,
  _id: 'aaf44e13cfe1e1a23b47aa090b005852'};


export function setEnvironmentMqtt(mqttSettings: IMqttServiceOptions) {
  Object.assign(ENVIRONMENT_MQTT_SERVICE_OPTIONS, mqttSettings);
}

export function setBaseUrl(newUrl: string) {
  API_BASE_URL = newUrl;
}

export function setVersionCheckUrl(newUrl: string) {
  VERSION_CHECK_URL = newUrl;
}

export function setMqttSettings(newMqttSettings: IMqttServiceOptions) {
  ENVIRONMENT_MQTT_SERVICE_OPTIONS = newMqttSettings;
}

export function setUseDali(useDali: boolean) {
  USE_DALI = useDali;
}

export function setSleepTimeout(timeout: number) {
  SLEEP_TIMEOUT = timeout;
}

export function setUseDeviceVisibleToUser(useDeviceVisibleToUser: boolean) {
  USE_DEVICE_VISIBLE_TO_USER = useDeviceVisibleToUser;
}

export function setDefaultPinDurationInDays(duration: number) {
  DEFAULT_PIN_DURATION_IN_DAYS = duration;
}

export const CARD_READER_URL = 'http://localhost:9986';

/*
* Start/End Date -> today or +/-n value from today in days
* Start/End Time -> now or fixed time in HH:mm format
* max -> in days, from now what is max end date
*/
export const DATE_PICKER_OPTIONS = {
  startDate: 'today',
  startTime: 'now',
  endDate: '+120',
  endTime: '12:00',
  maxEnd: 365
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
