import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectResolve } from './core/guards/projectResolve';
import { AuthGuard } from './core/guards/auth.guard';
import { UserPermissionGuard } from './core/guards/user-permission.guard';
import { HomeComponent } from './pages/home/home.component';
import { LandingComponent } from './pages/landing/landing.component';
import { LoginComponent } from './pages/login/login.component';
import { ActiveDevicesComponent } from './shared/components/active-devices/active-devices.component';


const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'landing', component: LandingComponent },
    { path: 'login', component: LoginComponent },
    { path: 'home', canActivate: [AuthGuard], resolve: { project: ProjectResolve },  component: HomeComponent },
    { path: 'users', canActivate: [AuthGuard, UserPermissionGuard], resolve: { project: ProjectResolve },
      loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule) },
    { path: 'project', canActivate: [AuthGuard, UserPermissionGuard], resolve: { project: ProjectResolve },
    loadChildren: () => import('./modules/project/project.module').then(m => m.ProjectModule) },
    { path: 'logs', canActivate: [AuthGuard, UserPermissionGuard], resolve: { project: ProjectResolve },
      loadChildren: () => import('./modules/logs/logs.module').then(m => m.LogsModule) },
    { path: 'alarms',  canActivate: [AuthGuard, UserPermissionGuard], resolve: { project: ProjectResolve },
      loadChildren: () => import('./modules/alarms/alarms.module').then(m => m.AlarmsModule) },
    { path: 'settings',  canActivate: [AuthGuard, UserPermissionGuard], resolve: { project: ProjectResolve },
    loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule) },
    { path: 'rooms',  canActivate: [AuthGuard, UserPermissionGuard], resolve: { project: ProjectResolve },
    loadChildren: () => import('./modules/rooms/rooms.module').then(m => m.RoomsModule) },
    { path: 'automation',  canActivate: [AuthGuard, UserPermissionGuard], resolve: { project: ProjectResolve },
    loadChildren: () => import('./modules/automation/automation.module').then(m => m.AutomationModule) },
    { path: 'scenes',  canActivate: [AuthGuard, UserPermissionGuard], resolve: { project: ProjectResolve },
    loadChildren: () => import('./modules/scenes/scenes.module').then(m => m.ScenesModule) },
    { path: 'modes',  canActivate: [AuthGuard, UserPermissionGuard], resolve: { project: ProjectResolve },
    loadChildren: () => import('./modules/modes/modes.module').then(m => m.ModesModule) },
    { path: 'admin-settings',  canActivate: [AuthGuard, UserPermissionGuard], resolve: { project: ProjectResolve },
    loadChildren: () => import('./modules/admin-settings/admin-settings.module').then(m => m.AdminSettingsModule) },
    { path: 'active-devices', canActivate: [AuthGuard, UserPermissionGuard], resolve: { project: ProjectResolve }, component: ActiveDevicesComponent},
    { path: 'analytics', loadChildren: () => import('./modules/analytics/analytics.module').then(m => m.AnalyticsModule) },
    { path: 'doors',  canActivate: [AuthGuard, UserPermissionGuard], resolve: { project: ProjectResolve },
    loadChildren: () => import('./modules/doors/doors.module').then(m => m.DoorsModule) },
    { path: '**', redirectTo: 'home' }

  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
