<div class="landing-full-screen">
  <div class="top-container flex-column flex-center">
    <i class="i-logo landing-logo"></i>
    <div class="text-1 margin-bottom20">{{ 'welcome_to_abas' | translate }}</div>
    <div class="white text-2 margin-bottom20">ABAS</div>
    <div class="text-3">ADRIA BUILDING AUTOMATION SYSTEM</div>
    <!-- <button class="margin-top60" (click)="openAbasCodeAlert()">Enter ABAS code</button> -->
  </div>
  <div class="flex-column flex-center">
    <ion-button  class="square-primary margin-top6 landing-btn" (click)="openAbasCodeAlert()">{{ 'enter_code' | translate }}</ion-button>

    <h4>{{ 'or' | translate }}</h4>
    <!-- <button (click)="demoMode()" class="margin-top30">Try ABAS demo mode</button> -->

    <ion-button class="square-primary margin-top6 landing-btn" (click)="demoMode()">{{ 'try_demo' | translate }}</ion-button>

  </div>
</div>
