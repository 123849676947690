import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { setBaseUrl, setVersionCheckUrl, setEnvironmentMqtt, setSleepTimeout } from 'src/environments/environment';
import { MqttService } from 'ngx-mqtt';
import { MqttProjectService } from '../app-load/mqtt-project.service';
import { timeout } from 'rxjs/operators';
import { UserService } from './user.service';
import { Network } from '@ionic-native/network/ngx';
import { AlarmAlertsService } from 'src/app/modules/alarms/services/alarm-alerts.service';
import { Configuration } from '../models/configuration.model';


@Injectable({
  providedIn: 'root'
})
export class NetworkService {
    private networkType$ = new BehaviorSubject<'public' | 'local' | 'switching'>(null);
    private usingNetworkSwitcher$ = new BehaviorSubject<boolean>(false);
    networkSwitcherInitiated = false;
    networkCheckInterval: NodeJS.Timeout;
    switcherTimeout: NodeJS.Timeout;
    networkSwitcherDefaultPingInterval = 2000;
    networkSwitcherPingInterval: number;


    constructor(
        private http: HttpClient,
        private mqttService: MqttService,
        private mqttProjectService: MqttProjectService,
        private userService: UserService,
        private network: Network,
        private alarmAlertService: AlarmAlertsService
                ) {
    }

    public getNetworkType() {
      return this.networkType$;
    }

    public setNetworkType(netType: 'public' | 'local' | 'switching'){
      // console.log("setting network observable to " + netType);
      this.networkType$.next(netType);
    }

    public getUsingNetworkSwitcher(){
        return this.usingNetworkSwitcher$;
    }

    public setUsingNetworkSwitcher(usingNetworkSwitcher: boolean){
        if (this.networkSwitcherInitiated && !usingNetworkSwitcher) {
          clearInterval(this.networkCheckInterval);
          this.networkSwitcherInitiated = false;
        }
        this.usingNetworkSwitcher$.next(usingNetworkSwitcher);
    }

    public initNetworkSwitcher(config: Configuration) {
            if (config.NETWORK_SWITCHER_PING_INTERVAL) {
              this.networkSwitcherPingInterval = config.NETWORK_SWITCHER_PING_INTERVAL;
            } else {
              this.networkSwitcherPingInterval = this.networkSwitcherDefaultPingInterval;
            }

            this.setUsingNetworkSwitcher(config.USE_NETWORK_SWITCHER);
            if (config.USE_NETWORK_SWITCHER) {
                if (!this.networkSwitcherInitiated) {
                  this.networkSwitcherInitiated = true;
                  // console.log('activating network switcher. Should happen only once');
                  this.networkCheckInterval = setInterval(() => {
                    this.networkCheck(config);
                  }, this.networkSwitcherPingInterval);
                }
            }
        }

      public async networkCheck(config: Configuration){
        try {
          const networkSwitcherPingTimeout: number = Configuration.getNetworkSwitcherPingTimeout(config);

          await lastValueFrom(this.http.get(`${config.API_BASE_LOCAL_URL}/status`, {responseType: 'text'})
          .pipe(timeout(networkSwitcherPingTimeout)));
          if ((this.getNetworkType().getValue() === 'public' || !this.getNetworkType().getValue()) && (this.wifiIsAvailable() || this.ethernetIsAvailable())) {
            this.setLocalNetwork(config); // sets local network on login screen when there is no user
            if (this.userService.getUser()) {
              this.setNetworkType('switching');
              this.switcherTimeout = setTimeout(() => {
                console.log('switching to local timeout 20 secs');
                if (this.getNetworkType().getValue() === 'switching') {
                  this.setNetworkType(null);
                  console.log('network is still trying to switch to local network but not completing');
                  console.log('setting network type to null');
                }
               } , 20000);

              this.mqttProjectService.unsubscribeFromMqtt();
              this.mqttService.disconnect();
              setTimeout(async () => {
                try {
                  this.mqttService.connect(config.MQTT_LOCAL_SETTINGS);
                  await this.mqttProjectService.initMqttForProject();
                  this.alarmAlertService.reInitializeAlarmService();
                  this.setNetworkType('local');
                  clearTimeout(this.switcherTimeout);
                } catch (e) {
                  this.setNetworkType(null);
                  clearTimeout(this.switcherTimeout);
                }
              }, 1000);
            }
          }

        } catch (err) {
          if ((this.getNetworkType().getValue() === 'local' || !this.getNetworkType().getValue()) &&
           (this.mobileDataIsAvailable() || this.wifiIsAvailable() || this.ethernetIsAvailable())) {
            this.setPublicNetwork(config); // sets public network on login screen when there is no user
            if (this.userService.getUser()) {
              this.setNetworkType('switching');
              this.switcherTimeout = setTimeout(() => {
                console.log('switching to public timeout 20 secs');
                if (this.getNetworkType().getValue() === 'switching') {
                  this.setNetworkType(null);
                  console.log('network is still trying to switch to public network but not completing');
                  console.log('setting network type to null');
                }
               }, 20000);
              this.mqttProjectService.unsubscribeFromMqtt();
              this.mqttService.disconnect();
              setTimeout(async () => {
                try {
                this.mqttService.connect(config.MQTT_OVPN_SETTINGS);
                await this.mqttProjectService.initMqttForProject();
                this.alarmAlertService.reInitializeAlarmService();
                this.setNetworkType('public');
                clearTimeout(this.switcherTimeout);
                } catch (e) {
                  this.setNetworkType(null);
                  clearTimeout(this.switcherTimeout);
                }
            }, 1000);
          }
        }
      }
    }

    public setLocalNetwork(config: Configuration){
        this.setNetworkType('local');
        setBaseUrl(config.API_BASE_LOCAL_URL);
        setSleepTimeout(config.SLEEP_TIMEOUT);
        setVersionCheckUrl(config.API_BASE_LOCAL_URL + config.VERSION_CHECK_URL);
        setEnvironmentMqtt(config.MQTT_LOCAL_SETTINGS);
      }

      public setPublicNetwork(config: Configuration){
        this.setNetworkType('public');
        setBaseUrl(config.API_BASE_OVPN_URL);
        setSleepTimeout(config.SLEEP_TIMEOUT);
        setVersionCheckUrl(config.API_BASE_OVPN_URL + config.VERSION_CHECK_URL);
        setEnvironmentMqtt(config.MQTT_OVPN_SETTINGS);
     }


      mobileDataIsAvailable(): boolean {
        return this.network.type === 'cellular'
        || this.network.type === '2g'
        || this.network.type === '3g'
        || this.network.type === '4g';
      }

      wifiIsAvailable(): boolean {
        return this.network.type === 'wifi';
      }

      ethernetIsAvailable(): boolean {
        return this.network.type === 'ethernet';
      }
  }

      /*     Connection:
      CELL: "cellular"
      CELL_2G: "2g"
      CELL_3G: "3g"
      CELL_4G: "4g"
      ETHERNET: "ethernet"
      NONE: "none"
      UNKNOWN: "unknown"
      WIFI: "wifi" */
      // public setDemoNetwork(){
      //   console.log('seting demo network');
      //   this.setNetworkType('demo');
      //   setBaseUrl(API_BASE_DEMO_URL);
      //   setVersionCheckUrl(API_BASE_DEMO_URL + VERSION_CHECK_URL);
      //   setEnvironmentMqtt(ENVIRONMENT_MQTT_SERVICE_OPTIONS_DEMO);
      // }
