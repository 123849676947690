import { Component, OnInit, OnDestroy } from '@angular/core';

import { User } from '../../../core/models/user/user.model';
import { UserService } from 'src/app/core/services/user.service';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { DemoService } from 'src/app/core/services/demo.service';
import { MqttService } from 'ngx-mqtt';
import { Subscription } from 'rxjs';
import { MenuController } from '@ionic/angular';
import { MqttProjectService } from 'src/app/core/app-load/mqtt-project.service';
import { RoomViewService } from 'src/app/modules/rooms/services/room-view.service';
import { AlarmAlertsService } from 'src/app/modules/alarms/services/alarm-alerts.service';
import { Project } from 'src/app/core/models/project/project.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  project: Project;
  user: User;
  demo: boolean;
  userSubscription: Subscription;
  demoSubscription: Subscription;
  projectSubscription: Subscription;


  constructor(private authService: AuthenticationService,
              private userService: UserService,
              private demoService: DemoService,
              private mqttService: MqttService,
              private menuController: MenuController,
              private mqttProjectService: MqttProjectService,
              private roomViewService: RoomViewService,
              private alarmAlertService: AlarmAlertsService,
              private projectService: ProjectService
              ) { }

  ngOnInit() {
    this.user = Object.assign(new User(), this.userService.getUser());
    this.userSubscription = this.userService.userChanged.subscribe(() => {
      this.user = Object.assign(new User(), this.userService.getUser());
    });
    this.demoSubscription = this.demoService.getDemo$().subscribe((demoStatus: boolean) => {
      this.demo = demoStatus;
    });
    this.project = this.projectService.getProject();
    this.projectSubscription = this.projectService.projectChanged.subscribe(() => {
      this.project = this.projectService.getProject();
    });

  }

  userIsGuest(user: User): boolean {
    return User.isGuest(user);
  }

  userIsAdmin(user: User): boolean {
    return User.isAdmin(user);
  }

  userIsOwner(user: User): boolean {
    return User.isOwner(user);
  }

  onRouteToRooms() {
    this.onClickLink();
    this.roomViewService.setSelectedFloor(null);
  }


  onClickLink() {
    this.menuController.close();
  }

  logout() {
    this.userService.unsetUser();
    this.authService.logout();
    try {
      this.mqttService.disconnect();
    } catch (err) {
      console.log(err);
    }
    this.mqttProjectService.unsubscribeFromMqtt();
    this.alarmAlertService.unsubscribeFromMqttAlarms();
    if (this.demoService.isDemo()){
      this.demoService.setDemo(false);
    }
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.demoSubscription) {
      this.demoSubscription.unsubscribe();
    }
    if (this.projectSubscription) {
      this.projectSubscription.unsubscribe();
    }
  }


}
